import React from 'react';
import { Segment, Label, Icon, Grid } from 'semantic-ui-react';
//import { Link } from 'react-router-dom';

export default function OfferDetailedSidebar({ attendees, hostUid }) {
    console.log('OfferDetailedSidebar dayiz');
  return (
    <>
      <Grid columns={1}>
        <Grid.Column>
          <Segment>
            <Label as='a' color='red' ribbon='right'>
              Neden geldi?
            </Label>
            <p>
              <Icon size='large' color='teal' name='info' />
              Sayın Yetkili,
            </p>
            <p>
              Kısa süre içerisinde sona erecek olan indiriminizi uzatarak
              satışlarımıza ivme kazandırmak isteriz.
            </p>
            <p>
              Talebimizle ilgili geri dönüşünüzü hızlıca alabilmek için bu form
              size gönderilmiştir.
            </p>
            <p>………………………………………………………</p>

            <Label as='a' color='teal' ribbon='right'>
              Ne yapmalıyım?
            </Label>
            <p>Yandaki formda,</p>
            <p>Hazırladıgımız talebe ait bilgiler bulunmaktadır.</p>
            <p>
              Bilgileri inceleyip <strong> "Değişiklik ve/veya onay" </strong>
              adlı linki tıklayarak gelen ekranda varsa değişikliklerinizi form
              üzerinde yaparak onaylayınız.
            </p>
            <p>Kalan işlemler tarafımızca derhal işleme alınacaktır.</p>
            <p>İşbirliğiniz için şimdiden teşekkür ederiz.</p>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
