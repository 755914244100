export const categoryData = [
    {key: 'evet', text: 'Evet', value: 'evet'},
    {key: 'hb', text: 'HB', value: 'hb'},
    {key: 'bb', text: 'BB', value: 'bb'},
    {key: 'fb', text: 'FB', value: 'fb'},
    {key: 'uai', text: 'UAI', value: 'uai'},
    {key: 'ai', text: 'AI', value: 'ai'},
    {key: 'ai+', text: 'AI+', value: 'ai+'},

];
