import React from 'react';
import { useSelector } from 'react-redux';
import TestModal from '../../../features/sandbox/TestModal';
import LoginForm from '../../../features/auth/LoginForm';
import RegisterForm from '../../../features/auth/RegisterForm';
import OfferExtensionModal from '../../../features/events/eventDetailed/OfferExtensionModal';
import OfferFormModal from '../../../features/offers/offerForm/OfferFormModal';


export default function ModalManager() {
  const modalLookup = {
      TestModal,
      LoginForm,
      RegisterForm,
      OfferExtensionModal,
      OfferFormModal,
  };
  const currentModal = useSelector((state) => state.modals);
  console.log('in ModalManager');
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
