import React from 'react';
import { Menu, Container, Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import SignedOutMenu from './SignedOutMenu';
import SignedInMenu from './SignedInMenu';
import { useSelector } from 'react-redux';

export default function NavBar({ setFormOpen }) {
  const {authenticated} = useSelector(state => state.auth);
 // const [authenticated, setAuthenticated] = useState(false); 

 const URLParams = new URLSearchParams(window.location.search); 
        const qrIdURL = URLParams.get('id');
        const aType = URLParams.get('aType');
        
        console.log(qrIdURL);
        console.log('navBar aType:'+aType);
        // let location = useLocation();
        // console.log('offerDetailHeader:..'+location.state.type.aType);
        // const aType= location.state.type.aType;
        
        
  return (
    <Menu inverted fixed='top'>
      <Container>
        <Menu.Item as={NavLink} exact to='/' header>
          <img src='/assets/logo.png' alt='logo' style={{ marginRight: 15, width:200}} />
          
        </Menu.Item>
        {authenticated ? (
          // <Menu.Item as={NavLink} to='/events' name='Events' />
          <Menu.Item as={NavLink} to='/offers' name='Offers' />
        ) : (
          <Menu.Item as={NavLink} to='/offers' name='Offers' />
        )}
        
        {/* <Menu.Item as={NavLink} to='/sandbox' name='Sandbox' /> */}
        {authenticated && (
          <Menu.Item as={NavLink} //to='/createOffer'
             to={{
                pathname: `/createOffer`,
                state: {
                    type: {aType}
                }
              }}>
            <Button negative positive inverted content='new Offer' />
          </Menu.Item>
        )}
        {authenticated ? (
          <SignedInMenu />
        ) : ( console.log('not authenticated')
          // <SignedOutMenu />
        )}
        {aType ? (<SignedOutMenu />) : (console.log('signOut hide')) }
      </Container>
    </Menu>
  );
}
