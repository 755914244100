import React from 'react';
import { Grid } from 'semantic-ui-react';
import OfferDetailedHeader from './OfferDetailedHeader';
import OfferDetailedInfo from './OfferDetailedInfo';
import OfferDetailedSidebar from './OfferDetailedSidebar';
import { useSelector, useDispatch } from 'react-redux';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { listenToEventFromFirestore } from '../../../app/firestore/firestoreService';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { Redirect, useLocation } from 'react-router-dom';
import { listenToSelectedEvent } from '../offerActions';

export default function OfferDetailedPage({ match }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const event = useSelector((state) => state.event.selectedEvent);
  const { loading, error } = useSelector((state) => state.async);
  const isHost = event?.hostUid === currentUser?.uid;
  const isGoing = event?.attendees?.some((a) => a.id === currentUser?.uid);

  let location = useLocation();
  console.log(location.state.type.aType);
  const aType = location.state.type.aType;

  useFirestoreDoc({
    query: () => listenToEventFromFirestore(match.params.id),
    data: (event) => dispatch(listenToSelectedEvent(event)),
    deps: [match.params.id, dispatch],
  });

  if (loading || (!event && !error))
    return <LoadingComponent content='Loading event...' />;

  if (error) return <Redirect to='/error' />;
  //console.log('OfferDetailedPage deyiz 23  :'+location.state.aType);

  return (
    <Grid>
      <Grid.Column width={10}>
        <OfferDetailedHeader event={event} isGoing={isGoing} isHost={isHost} />
        <OfferDetailedInfo event={event}  aType={aType} />
      </Grid.Column>
      <Grid.Column width={6}>
        <OfferDetailedSidebar
        //   attendees={event?.attendees}
        //   hostUid={event.hostUid}
        />
      </Grid.Column>
    </Grid>
  );
}
