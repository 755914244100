import React from 'react';
import { Segment, Item, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
//import { format } from 'date-fns';

export default function EventListItem({ event }) {
  console.log('EventListItem e geldi');
  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Header content={event.hotelName} />
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <span>
          <Icon name='marker' /> {event.contractName}
          <Icon name='clock' /> {event.category}
        </span>
      </Segment>

      <Segment clearing>
        <Button
          icon
          labelPosition='right'
          as={Link}
          to={`/events/${event.id}`}
          // color='teal'
          floated='right'
        >
          <Icon name='right arrow' inverted />
          Değişiklik ve/veya onay için başla
        </Button>
      </Segment>
    </Segment.Group>
  );
}
