import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

// const firebaseConfig = {
//     apiKey: "AIzaSyBKH2Jdt3tKFTGTovt5FImCrtfjlEFIRvo",
//     authDomain: "friendlychat-99dac.firebaseapp.com",
//     databaseURL: "https://friendlychat-99dac.firebaseio.com",
//     projectId: "friendlychat-99dac",
//     storageBucket: "friendlychat-99dac.appspot.com",
//     messagingSenderId: "638850177835",
//     appId: "1:638850177835:web:881fd6cefe03d603"
//   };

//   firebase.initializeApp(firebaseConfig);
//   firebase.firestore();

//   export default firebase;
const firebaseConfig = {
  apiKey: "AIzaSyDEkaP4TtBDFuoyIZrZAg8z4P7AC6T2ujA",
  authDomain: "bedsopia-7b541.firebaseapp.com",
  databaseURL: "https://aws.bedsopia.com",
  projectId: "bedsopia-7b541",
  storageBucket: "bedsopia-7b541.appspot.com",
  messagingSenderId: "1001310888079",
  appId: "1:1001310888079:web:d72b21bb5f5718fd5290fa"
};


firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
