import React, { useState } from 'react';
import { Segment, Image, Item, Header, Button, Icon } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
//import { toast } from 'react-toastify';
// import {
//   addUserAttendance,
//   cancelUserAttendance,
// } from '../../../app/firestore/firestoreService';
//import { useSelector } from 'react-redux';
import UnauthModal from '../../auth/UnauthModal';

const eventImageStyle = {
  filter: 'brightness(30%)',
};

const eventImageTextStyle = {
  position: 'absolute',
  bottom: '5%',
  left: '5%',
  width: '100%',
  height: 'auto',
  color: 'white',
};

export default function OfferDetailedHeader({ event, isHost, isGoing }) {
  // const [loading, setLoading] = useState(false);
  // const { authenticated } = useSelector((state) => state.auth);
  let location = useLocation();
  console.log('offerDetailHeader:..' + location.state.type.aType);
  const aType = location.state.type.aType;

  const [modalOpen, setModalOpen] = useState(false);
  //console.log('offerDetailedHeader:'+ aType );
  //console.log('OfferDetailedPage deyiz'+location.state);

  return (
    <>
      {modalOpen && <UnauthModal setModalOpen={setModalOpen} />}
      <Segment.Group>
        <Segment basic attached='top' style={{ padding: '0' }}>
          <Image
            src={`/assets/categoryImages/${event.category}.jpg`}
            fluid
            style={eventImageStyle}
          />
          <Segment basic style={eventImageTextStyle}>
            <Item.Group>
              <Item>
                <Item.Content>
                  <Header
                    size='huge'
                    content={event.hotelName}
                    style={{ color: 'white' }}
                  />
                </Item.Content>
              </Item>
            </Item.Group>
          </Segment>
        </Segment>

        <Segment attached='bottom' clearing>
          {event.activeMi ? (
            event.otelOnayladiMi ? (
              <Button
                icon
                labelPosition='right'
                as={Link}
                //to={`/manage/${event.id}`}
                to={{
                  pathname: `/manage/${event.id}`,
                  state: { type: { aType } },
                }}
                floated='right'
                disabled
              >
                {console.log('ccc......' + event.activeMi)}
                <Icon name='right arrow' inverted />
                Otel onay süreci tamamlandığı için - değişiklik yapılamaz!
              </Button>
            ) : (
              <Button
                icon
                labelPosition='right'
                as={Link}
                //to={`/manage/${event.id}`}
                to={{
                  pathname: `/manage/${event.id}`,
                  state: { type: { aType } },
                }}
                floated='right'
              >
                {console.log('aaa......' + event.activeMi)}
                <Icon name='right arrow' inverted />
                Değişiklik ve/veya onay
              </Button>
            )
          ) : (
            <Button
              icon
              labelPosition='right'
              as={Link}
              //to={`/manage/${event.id}`}
              to={{
                pathname: `/manage/${event.id}`,
                state: { type: { aType } },
              }}
              floated='right'
              disabled
            >
              {console.log('bbb......' + event.activeMi)}
              <Icon name='right arrow' inverted />
              Biten indirim - işlem yapılamaz!
            </Button>
          )}
        </Segment>
      </Segment.Group>
    </>
  );
}
