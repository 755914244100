import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import { Button, Divider, Header, Image, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function OfferFormModal() {

  return (
    <ModalWrapper size='mini' header='İşlem tamam!' >
      <Modal.Content image>
        <Image
          size='medium'
          src='https://react.semantic-ui.com/images/avatar/large/rachel.png'
          wrapped
        />
        <Divider></Divider>
        <Modal.Description>
          <Header>İndirim onayınızı aldık.</Header>
          <p>
            En kısa sürede bir e-posta ile sistemlerimize de işlediğimiz
            şekliyle sizleri bilgilendireceğiz. Tarayıcınızı kapatabilirsiniz.
          </p><p>Saygılarımızla</p>
          <p>bedsopia.com</p>
        </Modal.Description>
      </Modal.Content>
      <Divider></Divider>
      
      <Modal.Actions>
        <Button 
          as={Link}
          to={`/`}
          content='Teşekkür ederiz.'
          labelPosition='right'
          icon='checkmark'
          positive
        />
      </Modal.Actions>
      
    </ModalWrapper>
  );
}
