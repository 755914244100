import React from 'react';
import { Segment, Grid, Icon, Table, Header } from 'semantic-ui-react';
import { format } from 'date-fns';
//import EventDetailedMap from './EventDetailedMap';

export default function OfferDetailedInfo({ event, aType }) {
  //  const [mapOpen, setMapOpenToggle] = useState(false);
  console.log('in OfferDetailedInfo:' + aType);
  return (
    <>
      <Segment.Group>
        <Segment>
          {event.activeMi ? (
            <Header
              content='Talep ettiğimiz yeni indirim bilgileri'
              as='h3'
              style={{ display: 'block', marginBottom: 10 }}
            />
          ) : (
            <Header
              content='Biten indirim bilgileri'
              as='h3'
              style={{ display: 'block', marginBottom: 10 }}
            />
          )}
        </Segment>
        <Segment attached='top'>
          <Grid>
            <Grid.Column width={1}>
              <Icon size='large' color='teal' name='info' />
            </Grid.Column>
            <Grid.Column width={15}>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Başlama</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={5}>Uygulanacağı kontrat</Table.Cell>
                    <Table.Cell>{event.contractName}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Kategori</Table.Cell>
                    <Table.Cell>{event.category}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Cost currency</Table.Cell>
                    <Table.Cell>{event.costCurrency}</Table.Cell>
                  </Table.Row>
                  {aType ? (
                    <Table.Row>
                      <Table.Cell>Booking stay date?</Table.Cell>
                      <Table.Cell>
                        {event.bookingStayDateMi ? 'Evet' : 'Hayır'}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('bookingStayDateMi close')
                  )}
                  {aType ? (
                    <Table.Row>
                      <Table.Cell>Aktif mi?</Table.Cell>
                      <Table.Cell>
                        {event.activeMi ? 'Evet' : 'Hayır'}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('activeMi close')
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment attached>
          <Grid>
            <Grid.Column width={1}>
              <Icon size='large' color='teal' name='calendar' />
            </Grid.Column>
            <Grid.Column width={15}>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Başlama</Table.HeaderCell>
                    <Table.HeaderCell>Bitiş</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Satış tarihi</Table.Cell>
                    <Table.Cell>
                      {format(event.bookingDateFrom, 'dd.MM.yyyy')}
                    </Table.Cell>
                    <Table.Cell>
                      {format(event.bookingDateTo, 'dd.MM.yyyy')}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment attached>
          <Grid>
            <Grid.Column width={1}>
              <Icon size='large' color='teal' name='hotel' />
            </Grid.Column>
            <Grid.Column width={15}>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>İndirim oranı</Table.HeaderCell>
                    <Table.HeaderCell>Başlama</Table.HeaderCell>
                    <Table.HeaderCell>Bitiş</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Konaklama tarihi1</Table.Cell>
                    <Table.Cell>{`% ${event.stayDate1Percent}`}</Table.Cell>
                    <Table.Cell>
                      {format(event.stayDate1From, 'dd.MM.yyyy')}
                    </Table.Cell>
                    <Table.Cell>
                      {format(event.stayDate1To, 'dd.MM.yyyy')}
                    </Table.Cell>
                  </Table.Row>
                  {event.stayDate3Percent ? (
                    <Table.Row>
                      <Table.Cell>Konaklama tarihi2</Table.Cell>
                      <Table.Cell>{`% ${event.stayDate2Percent}`}</Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate2From, 'dd.MM.yyyy')}
                      </Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate2To, 'dd.MM.yyyy')}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('Konaklama tarihi3 boş')
                  )}

                  {event.stayDate3Percent ? (
                    <Table.Row>
                      <Table.Cell>Konaklama tarihi3</Table.Cell>
                      <Table.Cell>{`% ${event.stayDate3Percent}`}</Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate3From, 'dd.MM.yyyy')}
                      </Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate3To, 'dd.MM.yyyy')}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('Konaklama tarihi3 boş')
                  )}

                  {event.stayDate4Percent ? (
                    <Table.Row>
                      <Table.Cell>Konaklama tarihi4</Table.Cell>
                      <Table.Cell>{`% ${event.stayDate4Percent}`}</Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate4From, 'dd.MM.yyyy')}
                      </Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate4To, 'dd.MM.yyyy')}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('Konaklama tarihi4 boş')
                  )}

                  {event.stayDate5Percent ? (
                    <Table.Row>
                      <Table.Cell>Konaklama tarihi5</Table.Cell>
                      <Table.Cell>{`% ${event.stayDate5Percent}`}</Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate5From, 'dd.MM.yyyy')}
                      </Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate5To, 'dd.MM.yyyy')}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('Konaklama tarihi5 boş')
                  )}

                  {event.stayDate6Percent ? (
                    <Table.Row>
                      <Table.Cell>Konaklama tarihi6</Table.Cell>
                      <Table.Cell>{`% ${event.stayDate6Percent}`}</Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate6From, 'dd.MM.yyyy')}
                      </Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate6To, 'dd.MM.yyyy')}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('Konaklama tarihi6 boş')
                  )}

                  {event.stayDate7Percent ? (
                    <Table.Row>
                      <Table.Cell>Konaklama tarihi7</Table.Cell>
                      <Table.Cell>{`% ${event.stayDate7Percent}`}</Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate7From, 'dd.MM.yyyy')}
                      </Table.Cell>
                      <Table.Cell>
                        {format(event.stayDate7To, 'dd.MM.yyyy')}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('Konaklama tarihi7 boş')
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment attached>
          <Grid>
            <Grid.Column width={1}>
              <Icon size='large' color='teal' name='hotel' />
            </Grid.Column>
            <Grid.Column width={15}>
              <Segment>
                <Table definition>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell>Başlama</Table.HeaderCell>
                      <Table.HeaderCell>Bitiş</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width='5'>Konaklama gün sayısı</Table.Cell>
                      <Table.Cell>{event.stayDay1}</Table.Cell>
                      <Table.Cell>{event.stayDay2}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment attached>
          <Grid>
            <Grid.Column width={1}>
              <Icon size='large' color='teal' name='info' />
            </Grid.Column>
            <Grid.Column width={15}>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Durum</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {aType ? (
                    <Table.Row>
                      <Table.Cell width='5'>
                        Bir önceki indirimler geçerli mi?
                      </Table.Cell>
                      <Table.Cell>{event.oncekiIndirimlerGecerliMi}</Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('oncekiIndirimlerGecerliMi close')
                  )}

                  <Table.Row>
                    <Table.Cell width='5'>
                      Geçerli olduğu oda tipleri
                    </Table.Cell>
                    <Table.Cell>{event.gecerliOlduguOdaTipleri}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width='5'>Geçerli olduğu pazarlar</Table.Cell>
                    <Table.Cell>{event.gecerliOlduguPazarlar}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell width='5'>
                      Tüm boardlar için geçerli mi?
                    </Table.Cell>
                    <Table.Cell>{event.tumBoardlarIcinGecerliMi}</Table.Cell>
                  </Table.Row>

                  {aType ? (
                    <Table.Row>
                      <Table.Cell width='5'>JP offer kod</Table.Cell>
                      <Table.Cell>{event.jpOfferName}</Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('jpOfferName close')
                  )}

                  {aType ? (
                    <Table.Row>
                      <Table.Cell width='5'>E-mail gitti mi (PRODUCT)?</Table.Cell>
                      <Table.Cell>
                        {event.productaEmailGittiMi ? 'Evet' : 'Hayır'}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('jpOfferName close')
                  )}

                  {aType ? (
                    <Table.Row>
                      <Table.Cell width='5'>Product onayı var mı?</Table.Cell>
                      <Table.Cell>
                        {event.productOnayiVarMi ? 'Evet' : 'Hayır'}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('jpOfferName close')
                  )}

                  {aType ? (
                    <Table.Row>
                      <Table.Cell width='5'>Otelin E-mail adresi</Table.Cell>
                      <Table.Cell>{event.hotelEmail}</Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('hotelEmail close')
                  )}
                  {aType ? (
                    <Table.Row>
                      <Table.Cell width='5'>E-mail gitti mi (OTEL)?</Table.Cell>
                      <Table.Cell>
                        {event.oteleEmailGittiMi ? 'Evet' : 'Hayır'}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('oteleEmailGittiMi close')
                  )}
                  {aType ? (
                    <Table.Row>
                      <Table.Cell width='5'>İndirim girildi mi?</Table.Cell>
                      <Table.Cell>
                        {event.offerGirildiMi ? 'Evet' : 'Hayır'}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('offerGirildiMi close')
                  )}
                  
                    <Table.Row>
                      <Table.Cell width='5'>OTEL onayladı mı?</Table.Cell>
                      <Table.Cell>
                        {event.otelOnayladiMi ? 'Evet' : 'Hayır'}
                      </Table.Cell>
                    </Table.Row>
                  
                  {aType ? (
                    <Table.Row disabled>
                      <Table.Cell width='5'>Ticket Id</Table.Cell>
                      <Table.Cell>{event.id}</Table.Cell>
                    </Table.Row>
                  ) : (
                    console.log('event.id close')
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
    </>
  );
}
