//* global google */
import React, { useState } from 'react';
import {
  Segment,
  Header,
  Button,
  Confirm,
  Table,
  Grid,
} from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { listenToSelectedEvent, clearSelectedEvent } from '../offerActions';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { categoryData } from '../../../app/api/categoryOptions';
import MyDateInput from '../../../app/common/form/MyDateInput';
import {
  listenToEventFromFirestore,
  updateEventInFirestore,
  addEventToFirestore,
  cancelEventToggle,
} from '../../../app/firestore/firestoreService';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { roomTypeData } from '../../../app/api/roomTypeOptions';
import { marketsData } from '../../../app/api/marketsOptions';
import { oncekiIndirimlerData } from '../../../app/api/oncekiIndirimlerOptions';
import { openModal } from '../../../app/common/modals/modalReducer';


export default function OfferForm({ match, history, location }) {
  const dispatch = useDispatch();
  const [setLoadingCancel] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { selectedEvent } = useSelector((state) => state.event);
  const { loading, error } = useSelector((state) => state.async);

  useEffect(() => {
    if (location.pathname !== '/createOffer') return;
    dispatch(clearSelectedEvent());
  }, [dispatch, location.pathname]);

  console.log('offerForm: .........' + location.state.type.aType);
  const aType = location.state.type.aType;

  const initialValues = selectedEvent ?? {
    activeMi: false,
    hotelName: '',
    category: '',
    contractName: '',
    costCurrency: '',
    bookingStayDateMi: true,
    bookingStayDateFrom: null,
    bookingStayDateto: null,
    stayDate1From: null,
    stayDate1To: null,
    stayDate1Percent: 0,
    stayDate2From: null,
    stayDate2To: null,
    stayDate2Percent: 0,
    stayDate3From: null,
    stayDate3To: null,
    stayDate3Percent: 0,
    stayDate4From: null,
    stayDate4To: null,
    stayDate4Percent: 0,
    stayDate5From: null,
    stayDate5To: null,
    stayDate5Percent: 0,
    stayDate6From: null,
    stayDate6To: null,
    stayDate6Percent: 0,
    stayDate7From: null,
    stayDate7To: null,
    stayDate7Percent: 0,
    stayDay1: 0,
    stayDay2: 0,
    oncekiIndirimlerGecerliMi: false,
    tumBoardlarIcinGecerliMi: true,
    offerGirildiMi: false,
    otelOnayladiMi: false,
    oteleEmailGittiMi: false,
    productaEmailGittiMi: false,
    productOnayiVarMi: false,
    qrId: '',
    date: new Date(),
  };

  const validationSchema = Yup.object({
    bookingDateFrom: Yup.string().required(
      'You must provide a bookingDateFrom'
    ),
    bookingDateTo: Yup.string().required('You must provide a bookingDateTo'),
    oncekiIndirimlerGecerliMi: Yup.string().required(
      'You must provide a oncekiIndirimlerGecerliMi'
    ),
    category: Yup.string().required('You must provide a category'),
    tumBoardlarIcinGecerliMi: Yup.string().required(
      'You must provide a tumBoardlarIcinGecerliMi'
    ),
    activeMi: Yup.boolean().required('You must provide a activeMi'),
    hotelEmail: Yup.string().required().email(),

    //description: Yup.string().required(),
    // city: Yup.object().shape({
    //   address: Yup.string().required('City is required'),
    // }),
    // venue: Yup.object().shape({
    //   address: Yup.string().required('Venue is required'),
    // }),
    // date: Yup.string().required(),
  });

  async function handleCancelToggle(event) {
    setConfirmOpen(false);
    setLoadingCancel(true);
    try {
      await cancelEventToggle(event);
      setLoadingCancel(false);
    } catch (error) {
      setLoadingCancel(true);
      toast.error(error.message);
    }
  }

  useFirestoreDoc({
    shouldExecute:
      match.params.id !== selectedEvent?.id &&
      location.pathname !== '/createEvent',
    query: () => listenToEventFromFirestore(match.params.id),
    data: (event) => dispatch(listenToSelectedEvent([event])),
    deps: [match.params.id, dispatch],
  });

  if (loading) return <LoadingComponent content='Loading offer...' />;

  if (error) return <Redirect to='/error' />;

  return (
    <Segment clearing>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            selectedEvent
              ? await updateEventInFirestore(values)
              : await addEventToFirestore(values);
            setSubmitting(false);
            console.log(values);
            console.log(match.params.hotelName);
            dispatch(openModal({ modalType: 'OfferFormModal' }))
            //setConfirmOpen(true);
            //history.push(`OfferFormModal`); //${match.params.id}
          } catch (error) {
            toast.error(error.message);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid, values }) => (
          <Form className='ui form'>
            <Header
              content='Talep ettiğimiz yeni indirim bilgileri'
              as='h1'
              style={{ display: 'block', marginBottom: 10 }}
            />

            <Segment>
              {aType ? (
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width='5'>Otel adı</Table.Cell>
                      <Table.Cell>
                        <MyTextInput
                          name='hotelName'
                          placeholder='hotelName'
                          type='text'
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>Kategori</Table.Cell>
                      <Table.Cell>
                        <MyTextInput
                          name='category'
                          placeholder='category'
                          type='text'
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>Kontrat adı</Table.Cell>
                      <Table.Cell>
                        <MyTextInput
                          name='contractName'
                          placeholder='contractName'
                          type='text'
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>Döviz cinsi</Table.Cell>
                      <Table.Cell>
                        <MyTextInput
                          name='costCurrency'
                          placeholder='costCurrency'
                          type='text'
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>İndirim</Table.Cell>
                      <Table.Cell>

                    <MyTextInput 
                      label= 'Yürürlükte mi?'
                      name='activeMi'
                      placeholder='active mi'
                      type='checkbox'
                      floated='center'
                    />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell width='5'>Booking stay date?</Table.Cell>
                      <Table.Cell>
                      <MyTextInput
                      label= 'Booking stay date mi?'
                      name='bookingStayDateMi'
                      placeholder='bookingStayDate mi?'
                      type='checkbox'
                      floated='center'
                    />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ) : (
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width='5'>Otel adı</Table.Cell>
                      <Table.Cell>{values.hotelName}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>Kategori</Table.Cell>
                      <Table.Cell>{values.category}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>Kontrat adı</Table.Cell>
                      <Table.Cell>{values.contractName}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>Döviz cinsi</Table.Cell>
                      <Table.Cell>{values.costCurrency}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Segment>

            <Segment>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Başlama</Table.HeaderCell>
                    <Table.HeaderCell>Bitiş</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell width='5'>Satış tarihi</Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='bookingDateFrom'
                        placeholderText='Satış başlangıç tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='bookingDateTo'
                        placeholderText='Satış bitiş tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>İndirim oranı (%)</Table.HeaderCell>
                    <Table.HeaderCell>Başlama</Table.HeaderCell>
                    <Table.HeaderCell>Bitiş</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Konaklama tarihi1</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDate1Percent'
                        placeholder='Oran'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate1From'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate1To'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell width='5'>Konaklama tarihi2</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDate2Percent'
                        placeholder='oran'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate2From'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate2To'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell width='5'>Konaklama tarihi3</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDate3Percent'
                        placeholder='oran'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate3From'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate3To'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width='5'>Konaklama tarihi4</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDate4Percent'
                        placeholder='oran'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate4From'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate4To'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width='5'>Konaklama tarihi5</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDate5Percent'
                        placeholder='oran'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate5From'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate5To'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width='5'>Konaklama tarihi6</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDate6Percent'
                        placeholder='oran'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate6From'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate6To'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width='5'>Konaklama tarihi7</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDate7Percent'
                        placeholder='oran'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate7From'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyDateInput
                        name='stayDate7To'
                        placeholderText='Konaklama başlama tarihi '
                        dateFormat='dd.MM.yyyy'
                        autoComplete='off'
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            <Segment>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Gün ile</Table.HeaderCell>
                    <Table.HeaderCell>Gün arası</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width='5'>Konaklama gün sayısı</Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDay1'
                        placeholder='Gün1'
                        type='number'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MyTextInput
                        name='stayDay2'
                        placeholder='Gün2'
                        type='number'
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>

            {aType ? (
              <Segment>
                <Table definition>
                  <Table.Body>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Seçiminiz</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Row>
                      <Table.Cell width='5'>
                        Bir önceki indirimler geçerli mi?
                      </Table.Cell>
                      <Table.Cell>
                        <MySelectInput
                          name='oncekiIndirimlerGecerliMi'
                          placeholder='oncekiIndirimlerGecerliMi'
                          options={oncekiIndirimlerData}
                        />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell width='5'>
                        Geçerli olduğu oda tipleri
                      </Table.Cell>
                      <Table.Cell>
                        <MySelectInput
                          name='gecerliOlduguOdaTipleri'
                          placeholder='gecerliOlduguOdaTipleri'
                          options={roomTypeData}
                        />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell width='5'>Geçerli olduğu pazarlar</Table.Cell>
                      <Table.Cell>
                        <MySelectInput
                          name='gecerliOlduguPazarlar'
                          placeholder='gecerliOlduguPazarlar'
                          options={marketsData}
                        />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell width='5'>
                        Tüm boardlar için geçerli mi?
                      </Table.Cell>
                      <Table.Cell>
                        <MySelectInput
                          name='tumBoardlarIcinGecerliMi'
                          placeholder='tumBoardlarIcinGecerliMi'
                          options={categoryData}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width='5'>TaskId</Table.Cell>
                      <Table.Cell>
                        <MyTextInput
                          name='qrId'
                          placeholder='taskId giriniz'
                          type='text'
                        />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell width='5'>JP Offer kod</Table.Cell>
                      <Table.Cell>
                        <MyTextInput
                          name='jpOfferName'
                          placeholder='JP offer kodunu giriniz'
                          type='text'
                        />
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell width='5'>Otelin E-mail adresi</Table.Cell>
                      <Table.Cell>
                        <MyTextInput
                          name='hotelEmail'
                          placeholder='otelin E-mail adresini giriniz'
                          type='text'
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
            ) : (
              console.log('grup close')
            )}

            <Segment>
              <Grid columns={5}>
                <Grid.Column>
                  {aType ? (
                    <MyTextInput
                      label='e-mail gitti mi (PRODUCT)?'
                      name='productaEmailGittiMi'
                      placeholder='producta EmailGitti mi'
                      type='checkbox'
                      floated='center'
                    />
                  ) : (
                   console.log(history.productaEmailGittiMi)
                  )}
                </Grid.Column>
                <Grid.Column>
                  {aType ? (
                    <MyTextInput
                      label={
                        history.productOnayiVarMi
                          ? 'Product onayladı'
                          : 'Product onayladı mı?'
                      }
                      name='productOnayiVarMi'
                      placeholder='onaylı mı'
                      type='checkbox'
                      floated='center'
                    />
                  ) : (
                    history.productOnayiVarMi
                  )}
                </Grid.Column>

                <Grid.Column>
                  {aType ? (
                    <MyTextInput
                      label={
                        history.oteleEmailGittiMi
                          ? 'E-Mail gitti (OTEL)'
                          : 'E-mail gitti mi (OTEL)?'
                      }
                      name='oteleEmailGittiMi'
                      placeholder='onaylı mı'
                      type='checkbox'
                      floated='center'
                    />
                  ) : (
                    history.oteleEmailGittiMi
                  )}
                </Grid.Column>

                <Grid.Column>
                  {aType ? (
                    <MyTextInput
                      label={
                        history.offerGirildiMi
                          ? 'İndirim girildi'
                          : 'İndirim girildi mi?'
                      }
                      name='offerGirildiMi'
                      type='checkbox'
                      textalign='right'
                    />
                  ) : (
                    history.offerGirildiMi
                  )}
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    label={
                      history.otelOnayladiMi
                        ? 'Otel onayladı'
                        : 'OTEL onayladı mı?'
                    }
                    name='otelOnayladiMi'
                    placeholder='onaylı mı'
                    type='checkbox'
                    floated='center'
                  />
                </Grid.Column>
              </Grid>
            </Segment>

            {/* {selectedEvent && (
              <Button
                loading={loadingCancel}
                type='button'
                floated='left'
                color={selectedEvent.isCancelled ? 'green' : 'red'}
                content={
                  selectedEvent.isCancelled
                    ? 'Reactivate event'
                    : 'Cancel Event'
                }
                onClick={() => setConfirmOpen(true)}
              />
            )} */}
            
            <Button
              loading={isSubmitting}
              disabled={
                !isValid || !dirty || isSubmitting || history.otelOnayladiMi
              }
              type='submit'
              floated='right'
              positive
              content='Değişiklikleri onaylıyoruz'
            />
            <Button
              disabled={isSubmitting}
              as={Link}
              to={`/offers`} ///${match.params.id}
              type='submit'
              floated='right'
              content='Vazgeç'
            />
            {/* <Button class='ui button' 
            onClick={() => dispatch(openModal({ modalType: 'OfferFormModal' }))}>Show Modal</Button> */}
            {/* <Button
        onClick={() =>
          dispatch(openModal({ modalType: 'TestModal' }))
        }
        content='Open Modal'
        color='teal'
      /> */}
          </Form>
        )}
      </Formik>
      <Confirm
        content={
          selectedEvent?.isSubmitting
            ? 'fsdkjhfkjshfds'
            : 'This will cancel the event - are you sure?'
        }
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleCancelToggle(selectedEvent)}
      />
    </Segment>
  );
}
