import React from 'react';
import { Segment, Item, Icon, Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
//import { format } from 'date-fns';

export default function OfferListItem({ event, aType }) {
  return (
    console.log('offerListItem aType:' + aType),
    console.log('aktiveMi22:...' + event.activeMi),
    (
      <Segment.Group>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
                <Icon size='large' name='hotel' />
                <Item.Header content={event.hotelName} />
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <Segment>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                <Icon name='servicestack' /> Uygulanacağı kontrat:{' '}
                {event.contractName}
              </Grid.Column>
              <Grid.Column>
                <Icon name='money bill alternate' />
                Currency:
                {event.costCurrency}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <Grid columns={3} divided>
            <Grid.Row>
              <Grid.Column>
                <Icon name='sun' /> İndirim kategorisi:{event.category}
              </Grid.Column>
              <Grid.Column>
                <Icon name='bell' />
                Satış başlama: {format(event.bookingDateFrom, 'dd.MM.yyyy')}
              </Grid.Column>
              <Grid.Column>
                <Icon name='bell slash' />
                Satış bitiş: {format(event.bookingDateTo, 'dd.MM.yyyy')}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment clearing>
          {console.log('activeMi ye girmeden once33:' + event.activeMi)}
          {event.activeMi ? (
            <Button
              icon='heart'
              labelPosition='left'
              label='Talep ettiğimiz YENİ indirim bilgileri'
              as={Link}
              to={{
                pathname: `/offers/${event.id}`,
                state: { type: { aType } },
              }}
              primary
              floated='right'
            ></Button>
          ) : (
            <Button
              icon
              labelPosition='left'
              label='Biten indirim bilgileri'
              as={Link}
              // to={`/offers/${event.id}`}
              to={{
                pathname: `/offers/${event.id}`,
                state: { type: { aType } },
              }}
              secondary
              floated='right'
            ></Button>
          )}
        </Segment>
      </Segment.Group>
    )
  );
}
