import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import OfferList from './OfferList';
import { useSelector, useDispatch } from 'react-redux';
import OfferListItemPlaceholder from './OfferListItemPlaceholder';
import { fetchEvents } from '../offerActions';
import { useState } from 'react';
import { useEffect } from 'react';
import { RETAIN_STATE } from '../offerConstants';

export default function OfferDashboard() {
  const limit = 2;
  const dispatch = useDispatch();
  const { events, moreEvents, filter, startDate, lastVisible, retainState } = useSelector((state) => state.event);
  const { loading } = useSelector((state) => state.async);
  //const { authenticated } = useSelector((state) => state.auth);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const URLParams = new URLSearchParams(window.location.search); 
        const qrIdURL = URLParams.get('id');
        const aType = URLParams.get('aType');
        
        console.log(qrIdURL);
        console.log('offerDashboard aType:'+aType);

  useEffect(() => {
    if (retainState) return;
    setLoadingInitial(true);
    dispatch(fetchEvents(filter, startDate, limit)).then(() => {
      setLoadingInitial(false);
    });
    return () => {
      dispatch({type: RETAIN_STATE})
    }
  }, [dispatch, filter, startDate, retainState]);

  function handleFetchNextEvents() {
    dispatch(fetchEvents(filter, startDate, limit, lastVisible));
  }

  return (
    <Grid>
      <Grid.Column width={10}>
        {loadingInitial && (
          <>
            <OfferListItemPlaceholder />
            <OfferListItemPlaceholder />
          </>
        )}
        <OfferList
          events={events}
          getNextEvents={handleFetchNextEvents}
          loading={loading}
          moreEvents={moreEvents}
          aType={aType}
        />
      </Grid.Column>
      {/* <Grid.Column width={6}>
        {authenticated && <EventsFeed />}
        <EventFilters
          loading={loading}
        />
      </Grid.Column> */}
      <Grid.Column width={10}>
        <Loader active={loading} />
      </Grid.Column>
    </Grid>
  );
}
