import React from 'react';
import OfferListItem from './OfferListItem';
import InfiniteScroll from 'react-infinite-scroller';

export default function OfferList({
  events,
  getNextEvents,
  loading,
  moreEvents,
  aType
}) {
  return ( 
    <>
      { events.length !== 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextEvents}
          hasMore={!loading && moreEvents}
          initialLoad={false}
        >
          {events.map((event) => (
            <OfferListItem event={event} key={event.id} aType={aType}/>
          ))}
        </InfiniteScroll>
      )}
    </>
  );
  
}
